export const trackingsAmplitude = {
  AGENDAMENTO_ONLINE_PACIENTE: 'AGENDAMENTO_ONLINE_PACIENTE',
  ANAMNESE_ASSINATURA_PACIENTE: 'ANAMNESE_ASSINATURA_PACIENTE',
  ANAMNESE_ASSINATURA_PACIENTE_ASSINAR_AQUI: 'ANAMNESE_ASSINATURA_PACIENTE_ASSINAR_AQUI',
  ANAMNESE_ASSINATURA_PACIENTE_ENVIO_EMAIL: 'ANAMNESE_ASSINATURA_PACIENTE_ENVIO_EMAIL',
  ANAMNESE_ASSINATURA_PACIENTE_ENVIO_SELECAO: 'ANAMNESE_ASSINATURA_PACIENTE_ENVIO_SELECAO',
  ANAMNESE_ASSINATURA_PACIENTE_ENVIO_WHATSAPP: 'ANAMNESE_ASSINATURA_PACIENTE_ENVIO_WHATSAPP',
  ANAMNESE_CONFIRME_DADOS_BOTAO_CONTINUAR: 'ANAMNESE_CONFIRME_DADOS_BOTAO_CONTINUAR',
  EVOLUCAO_ASSINATURA_LINK_BOTAO_CONTINUAR: 'EVOLUCAO_ASSINATURA_LINK_BOTAO_CONTINUAR',
  EVOLUCAO_ASSINATURA_LINK_BOTAO_CONTINUAR_ASS: 'EVOLUCAO_ASSINATURA_LINK_BOTAO_CONTINUAR_ASS',
  EVOLUCAO_ASSINATURA_LINK_BOTAO_CONTINUAR_ASSINAR: 'EVOLUCAO_ASSINATURA_LINK_BOTAO_CONTINUAR_ASSINAR',
  EVOLUCAO_ASSINATURA_LINK_BOTAO_DENTISTA_ASSINAR_EVOLUCAO: 'EVOLUCAO_ASSINATURA_LINK_BOTAO_DENTISTA_ASSINAR_EVOLUCAO',
  EVOLUCAO_ASSINATURA_LINK_BOTAO_ENVIAR_ASSINATURA_PACIENTE: 'EVOLUCAO_ASSINATURA_LINK_BOTAO_ENVIAR_ASSINATURA_PACIENTE',
  EVOLUCAO_ASSINATURA_LINK_BOTAO_PACIENTE_ASSINAR_EVOLUCAO: 'EVOLUCAO_ASSINATURA_LINK_BOTAO_PACIENTE_ASSINAR_EVOLUCAO',
  EVOLUCAO_ASSINATURA_LINK_BOTAO_REALIZAR_ASSINATURA_AQUI: 'EVOLUCAO_ASSINATURA_LINK_BOTAO_REALIZAR_ASSINATURA_AQUI',
  EVOLUCAO_ASSINATURA_LINK_MODAL_BOTAO_ENVIAR_DOCUMENTOS: 'EVOLUCAO_ASSINATURA_LINK_MODAL_BOTAO_ENVIAR_DOCUMENTOS',
  EVOLUCAO_ASSINATURA_LINK_MODAL_ENVIAR_DOCUMENTOS_EMAIL: 'EVOLUCAO_ASSINATURA_LINK_MODAL_ENVIAR_DOCUMENTOS_EMAIL',
  EVOLUCAO_ASSINATURA_LINK_MODAL_ENVIAR_DOCUMENTOS_WHATSAPP: 'EVOLUCAO_ASSINATURA_LINK_MODAL_ENVIAR_DOCUMENTOS_WHATSAPP',
  HOF_EVOLUCAO_CONCLUIR_ASSINATURA_DENTISTA: "HOF_EVOLUCAO_CONCLUIR_ASSINATURA_DENTISTA",
  HOF_EVOLUCAO_CONCLUIR_ASSINATURA_PACIENTE: "HOF_EVOLUCAO_CONCLUIR_ASSINATURA_PACIENTE",
  HOF_EVOLUCAO_ENVIAR_ASSINATURA_PACIENTE: 'HOF_EVOLUCAO_ENVIAR_ASSINATURA_PACIENTE',
  HOF_ORCAMENTO_CONCLUIR_ASSINATURA_DENTISTA: 'HOF_ORCAMENTO_CONCLUIR_ASSINATURA_DENTISTA',
  HOF_ORCAMENTO_CONCLUIR_ASSINATURA_PACIENTE: 'HOF_ORCAMENTO_CONCLUIR_ASSINATURA_PACIENTE',
  HOF_ORCAMENTO_ENVIAR_ASSINATURA_PACIENTE: "HOF_ORCAMENTO_ENVIAR_ASSINATURA_PACIENTE",
  ORCAMENTO_ASSINATURA_PACIENTE: 'ORCAMENTO_ASSINATURA_PACIENTE'
}
